import http from "./http.js";

async function getCurrentUser() {
  const response = await http.get("users/current");
  return {
    id: response.data.id,
    name: response.data.name,
    role: response.data.role,
    contactPersonId: response.data.contactPersonId,
    isBlocked: response.data.isBlocked,
    clientId: response.data.clientId,
  };
}

async function getCurrentContactPerson() {
  const response = await http.get("users/current-contact-person");
  return {
    contactPersonTitle: response.data.contactPersonTitle,
    clientTitle: response.data.clientTitle ? response.data.clientTitle : "ISG",
  };
}

async function generatePassword() {
  const response = await http.post("/users/generate-password");
  return response.data;
}

async function changePassword(newPassword, confirmPassword) {
  await http.patch("users/password", {
    newPassword,
    confirmPassword,
  });
}

async function getLoginInfo() {
  const response = await http.patch("users/restore-password");
  return {
    name: response.data.name,
    password: response.data.password,
  };

}

async function sendEmail(email) {
  const response = await http.patch("users/forgot-password", {}, {
    params: {
      name: email,
    },
  });
  return response.data.emailNotificationSuccess;
}

async function getUser(id) {
  const response = await http.get(`users/${id}`);
  return response.data;
}

export {
  getCurrentUser,
  getCurrentContactPerson,
  generatePassword,
  changePassword,
  getLoginInfo,
  sendEmail,
  getUser,
};