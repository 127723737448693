<template>
  <v-container
    fluid
    class="grey lighten-2"
  >
    <v-layout
      justify-center
      align-center
      class="mt-5 pt-5"
    >
      <v-flex
        xs10
        sm6
        md4
      >
        <v-card
          class="d-flex mt-5 flex-column justify-center align-center"
        >
          <v-avatar
            color="primary"
          >
            <v-icon
              color="white"
              large
            >
              mdi-truck
            </v-icon>
          </v-avatar>

          <h2
            class="text-center mt-4 text-uppercase"
          >
            Ваш пароль был изменен
          </h2>
          <v-card-text>
            <v-form>
              <v-flex class="my-1">
                <span class="ma-2 pt-0">Ваш новый пароль</span>
                <v-flex class="my-1">
                  <v-text-field
                    v-model="password"
                    class="ma-2 pt-0"
                    label="Пароль"
                    hide-details
                    outlined
                    dense
                    height="44"
                  />
                </v-flex>
                <v-flex>
                  <h5
                    class="text-center mt-1"
                  >
                    Пожалуйста, сохраните/перепишите себе новый пароль и нажмите кнопку Авторизоваться
                  </h5>
                </v-flex>
                <v-layout
                  column
                  class="mt-2"
                >
                  <v-flex
                    xs2
                    class="d-flex justify-center"
                  >
                    <v-btn @click="$router.push({name: 'login'})">
                      Авторизоваться
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import auth from "../../auth.js";
import * as usersApi from "../../backend/usersApi.js";

export default {
  data: () => ({
    password: {},
    token: "",
  }),
  created(){
    auth.logout();
    this.token = this.$router.currentRoute.query.Token;
    auth.login(this.token);
    this.getPassword();
  },
  methods: {
    async getPassword() {
      const user = await usersApi.getLoginInfo();
      this.password = user.password;
    },
  },
};
</script>
